<div class="mt-md-3 container-fluid">
    <div class="row ">

        <div class=" col-12 col-md-8 offset-md-2">
            <div class="row rmt-2" *ngIf="!loading_person">
                <div class="col-12  col-md-8 offset-md-2">

                    <h5 class="mb-1 text-secondary mb-4 text-center">

                        <i class="  fas fa-user"></i> Pagos realizados

                    </h5>

                    <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && !person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                        <img *ngIf="person?.person.gender != 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatar.png')">
                        <img *ngIf="person?.person.gender == 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatara-female.png')">
                    </div>


                    <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                        <img class="rounded-circle  " [src]="sanitize(person?.person?.file_selfie?.url)">
                    </div>

                    <div *ngIf="!loading_person && person?.person" class="text-center mb-3">
                        <div class="text-capitalize h4 text-dark">{{person?.person?.name}} {{person?.person?.lastName}}</div>
                        <div> {{getIdentity(person)}}</div>
                        <div> {{getContact(person)}}</div>
                    </div>

                </div>
            </div>

            <ng-container *ngIf="!loading && person?.person" class="mt-2">
                <div class="row rmt-2">
                    <div class="col-12  col-md-8 offset-md-2">

                        <div class="bg-white mt-4 my-3 p-3 bg-body2  rounded shadow-sm" *ngFor="let item of resp_paymentsCredits">

                           

                        <div class=" mt-2  w-100 text-end " *ngIf="!item?.payment?.reconciled_payment &&  item?.payment?.status &&  item?.payment?.typeTransaction!=='PENDING'">
                            <i (click)="setPayment(item)" class="far fa-edit text-primary cursor-pounter" ></i>
                        </div>
 <!-- 
                        <div (click)="paymentRollback(item?.payment?.code)"  class=" mt-2  w-100 text-end text-danger "  *ngIf="!(!item?.payment?.reconciled_payment &&  item?.payment?.status)">

                            <i class="fa-solid fa-caret-left"></i>  Revertir

                        </div> -->



                        
                            <div class="row mt-2 row-k">

                                <div class="col-12 mt-2  ">
                                    <div *ngIf="item?.payment?.file" [title]="item?.payment?.file?.url" class="text-primary fz-14" style="cursor:pointer">
                                        <span (click)="redirectIMG(item?.payment?.file?.url)">Ver Adjunto</span>
                                    </div>
                                </div>
                                
                                <div class="col-8   ">
                                    <div class="small text-secondary">Código</div>
                                    <div class=" text-dark small fw-bold"> {{item?.payment?.code}}</div>
                                </div>

                                <div class="col-4   ">

                                    <div class="text-end ms-auto">
                                        <div *ngIf="item?.payment?.typeTransaction !== 'DOMICILIATION'">
                                            <div class="small text-secondary">Conciliación</div>
                                            <div class=" small text-success fw-bold" [ngClass]="{'text-success': item?.payment?.typeTransaction == 'AUTO','text-secondary': item?.payment?.typeTransaction != 'AUTO'}"> {{item?.payment?.typeTransaction == 'AUTO'? item?.payment?.responseAutoConfirm?.source?findAutopago(item?.payment?.responseAutoConfirm?.source):'Auto':(item?.payment?.typeTransaction==='PENDING'?'Automática':'Manual')}}</div>
                                        </div>
                                        <div *ngIf="item?.payment?.typeTransaction === 'DOMICILIATION'">
                                            <div class="small text-secondary">Pago Domiciliado {{ item?.payment?.account_payment?.bank?.name }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="small text-secondary">Monto total</div>
                                    <div class=" text-dark fw-bold"> {{item?.payment?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                </div>


                                <div class="col-6 text-end ">
                                    <div class="small text-secondary">Estatus</div>
                                    <div class="text-success  fw-bold" *ngIf="item?.payment?.reconciled_payment">
                                        <div *ngIf="item?.payment?.typeTransaction!=='DOMICILIATION' && item?.payment?.status">
                                            <span class="badge text-bg-success">Aprobado</span>
                                        </div>
                                        <div *ngIf="item?.payment?.typeTransaction==='DOMICILIATION'">
                                            <div *ngIf="item?.payment?.amount===0">
                                                <span class="badge text-bg-danger">{{item?.payment?.description}}</span>
                                            </div>
                                            <div *ngIf="item?.payment?.amount>0 && item?.payment?.status">
                                                <span class="badge text-bg-success">Aprobado</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class=" fw-bold" *ngIf="!item?.payment?.reconciled_payment &&  item?.payment?.status">

                                        <span class="badge text-bg-warning" *ngIf="item?.payment?.typeTransaction!=='PENDING'">Por conciliar</span>
                                        <span class="badge text-bg-warning" *ngIf="item?.payment?.typeTransaction==='PENDING'">En proceso de validación</span>

                                    </div>

                                    <div  class=" fw-bold" *ngIf="!item?.payment?.status">

                                        <div *ngIf="item?.payment?.typeTransaction!=='DOMICILIATION'">
                                            <span class="badge text-bg-danger">{{item?.payment?.typeTransaction == 'AUTO'?'Transacción fallida': 'Anulado'}}</span>
                                        </div>
                                        <div *ngIf="item?.payment?.typeTransaction==='DOMICILIATION'">
                                            <span class="badge text-bg-danger">Rechazado por el Banco</span>
                                        </div>


                                    </div>

                                </div>


                                <div class="col-6 ">
                                    <div class="small text-secondary">Tasa de cambio a la fecha del pago</div>
                                    <div class=" fw-bold text-dark "> USD 1 = BS {{item?.payment?.rate | currency:'USD':'': '1.2-2'}}</div>
                                </div>


                                <div class="col-6 text-end">
                                    <div class="small text-secondary">Monto del pago </div>
                                    <div class=" fw-bold  text-dark"> {{item?.payment?.counterValue | currency:'USD':'': '1.2-2'}} {{item?.payment?.currency?.name}}</div>
                                </div>


                                <div class="col-6 " >
                                    
                                    <div class="small text-secondary">Fecha conciliación / Anulación</div>

                                    <div class=" fw-bold " *ngIf="item?.payment?.reconciled_payment_date || item?.payment?.reconciled_payment_ejecutive ">
                                    <div class=" text-dark"> {{item?.payment?.reconciled_payment_date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                                    <div class=" text-dark" *ngIf="!item?.payment?.reconciled_payment"> {{item?.payment?.reconciled_payment_ejecutive | date: 'dd/MM/yyyy h:mm:ss a'}}</div>
                                   </div>
                                   <div *ngIf="!(item?.payment?.reconciled_payment || item?.payment?.reconciled_payment_ejecutive)">
                                        -
                                   </div>
   
                                </div>

                                <div class="col-6 text-end">
                                    <div class="small text-secondary ">Responsable </div>
                                    <div class=" fw-bold text-dark small text-capitalize" >
                                        <div class=" text-dark  text-capitalize"  >{{item?.payment?.reconciled_payment_ejecutive?.name}} {{item?.payment?.reconciled_payment_ejecutive?.lastName}}</div>
                                        <div class=" text-dark  text-capitalize" *ngIf="!item?.payment?.reconciled_payment_ejecutive"  >-</div>

                                    </div>

                                </div>

                                <div class="col-12  text-end" >
                                    <div class="small text-secondary">Motivo de anulación </div>
                                    <div class=" text-dark fw-bold" > {{item?.payment?.description || '-'}}</div>
                                </div>

                                            


                                <div class="col-6">
                                    <div class="small text-secondary ">Fecha de reporte</div>
                                    <div class=" text-dark fw-bold"> {{item?.payment?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                                </div>

                        
                                <div class="col-6 text-end">

                                    <div class="small text-secondary ">Canal de reporte </div>
                                    <div class=" fw-bold text-dark small text-capitalize"  *ngIf="item?.payment?.creator_api">{{item?.payment?.creator_api}} / {{item?.payment?.creator?.name}} {{item?.payment?.creator?.lastName}}</div>
                                    <div class=" fw-bold text-dark small text-capitalize" *ngIf="!item?.payment?.creator_api" >-</div>

                                </div>


                                <div class="col-6" >

                                    <div class="small text-secondary">Fecha de pago</div>
                                    <div class=" text-dark fw-bold" *ngIf="item?.payment?.register_payment_date"> {{ toDateFn(item?.payment?.register_payment_date) }}</div>
                                    <div class=" text-dark fw-bold" *ngIf="!item?.payment?.register_payment_date"> {{ toDateFn(item?.payment?.date) }}</div>


                                </div>

                                <div class="col-6  text-end" >
                                    <div class="small text-secondary">  Responsable edición  </div>
                                    <div class=" text-dark fw-bold"  *ngIf="item?.payment?.payment_edit_ejecutive">  {{item?.payment?.payment_edit_ejecutive?.name}} {{item?.payment?.payment_edit_ejecutive?.lastName}}</div>
                                    <div class=" fw-bold text-dark small text-capitalize" *ngIf="!item?.payment?.payment_edit_ejecutive" >-</div>

                                </div>


                                    
                                <div class="col-6   "  >
                                    <div class="small text-secondary">Banco origen </div>
                                    <div class=" text-dark fw-bold"> {{(item?.payment?.bankCode  && banks[item?.payment?.bankCode])  || '-'}}</div>
                                </div>


                                <div class="col-6  text-end" >
                                    <div class="small text-secondary">Método de pago</div>
                                    <div class=" text-dark fw-bold" >  {{item?.payment?.account_payment?.payment_method?.name || '-'}}</div>
                                </div>



                                <div class="col-12" *ngIf="item?.payment?.automatic_direct_debit?.error">
                                    <div class="small text-secondary">Motivo de Rechazo</div>
                                    <div class=" text-dark">
                                        {{item?.payment?.automatic_direct_debit?.error?.description }}
                                    </div>
                                </div>


                                <div class="col-6 mt-2" >
                                    <div class="small text-secondary">Teléfono de origen</div>
                                    <div class=" text-dark fw-bold"  > {{item?.payment?.phone ? item?.payment?.phone :'-'}}</div>
                                </div>

                                <div class="col-6  text-end " >
                                    <div class="small text-secondary">Número de referencia</div>
                                    <div class=" text-dark fw-bold"> {{item?.payment?.paymentMethodId  || '-'}}</div>
                                </div>
                        

                                <div class="col-6" >
                                    <div class="small text-secondary">Cuenta origen (Últimos 6)</div>
                                    <div class=" text-dark  fw-bold"> {{item?.payment?.account || '-' }}</div>
                                </div>

                                <div class="col-6  text-end" >

                                    <div class="small text-secondary">Cédula/Rif titular de la Cta.</div>
                                    <div class=" text-dark  fw-bold" > {{item?.payment?.cid != 'V' ? item?.payment?.cid :'-'}}</div>
                                </div>


                                <div class="col-12"  >
                                    <div class="small text-secondary">Banco destino</div>
                                    <div class=" text-dark  fw-bold"> {{item?.payment?.account_payment?.bank?.name || '-'}} </div>
                                </div>

                                <div class="col-6" >
                                    <div class="small text-secondary">Teléfono destino</div>
                                    <div class=" text-dark  fw-bold">{{item?.payment?.account_payment?.phone || '-'}} </div>
                                </div>

                                <div class="col-6  text-end" >
                                    <div class="small text-secondary">Cuenta destino</div>
                                    <div class=" text-dark  fw-bold" >{{item?.payment?.account_payment?.number || '-'}} </div>
                                </div>

                                <div class="col-6" *ngIf="item?.payment?.totalPaymentDue > 0" >

                                    <div class="small text-secondary">Monto sin aplicar</div>
                                    <div class=" text-dark  fw-bold">{{item?.payment?.totalPaymentDue}} </div>

                                </div>

                                

                                





                                

                              <!-- <div class="col-12 mt-2"  *ngIf="item?.payment?.description">
                                    <div class="small text-secondary">Descripción</div>
                                    <div class=" text-dark"> {{item?.payment?.description }}</div>
                                </div>
                                -->
                            </div>  

                            <div class="row mt-3 row-k" *ngIf="item?.payment?.fees?.length > 0">


                                <div class="fw-bold text-secondary">Comisión</div>
                                <ng-container class="mt-2" *ngFor=" let row of item?.payment?.fees ;let i = index">


                                    <div class="col-6">
                                        <div class="small ">
                                            <span>
                                                {{ row?.concept_deduction?.name  }}

                                            </span>

                                        </div>

                                        <div class="small text-secondary small">{{row?.code }}</div>

                                    </div>
                                    <div class="col-6  text-end " >
                                        <div class=" text-dark"> 
                                            <ng-container *ngIf="row?.commission_type=='FIXED'">{{row?.fixed_amount | currency:'USD':'': '1.2-2'}} USD</ng-container>
                                        </div>
                                    </div>

                                </ng-container>

                            </div>


                            <div class="fw-bold text-secondary mt-2 mb-2" *ngIf="item?.payment?.responseAutoConfirm">Voucher</div>

                            <div class="text-dark  fw-bold fz-12 mb-2" *ngIf="item?.payment?.responseAutoConfirm?.descripcion">
                                {{item?.payment?.responseAutoConfirm?.descripcion}}
                            </div>

                            <div class="row" *ngIf="item?.payment?.responseAutoConfirm?.voucher">
                                <div class="col-12 col-md-6">
                                    <div class="voucher p-2 text-dark text-uppercase" [innerHTML]="item?.payment?.responseAutoConfirm?.voucher">

                                    </div>

                                </div>
                            </div>

                            <div class="row mt-2 row-k" *ngIf="item.payment.payment_pending">
                                <div class="col-6" >
                                    <!-- <div class=" text-dark  fw-bold">{{item?.payment?.account_payment?.phone || '-'}} </div> -->
                                </div>
    
                                <div class="col-6  text-end" >
                                    <div class="small text-secondary">
                                        <span class="badge text-bg-success" [class.text-bg-success]="item.payment.payment_pending.status==='PENDING'" [class.text-bg-secondary]="item.payment.payment_pending.status==='EXPIRED'" [class.text-bg-primary]="item.payment.payment_pending.status==='COMPLETE'"><i class="fas fa-clock"></i>&nbsp;Asíncrono</span>
                                        <small *ngIf="item.payment.payment_pending.status==='PENDING'"><br>{{ item.payment.payment_pending.next_attempt_at | date: 'dd/MM/yyyy h:mm:ss a' }}</small>
                                        <small *ngIf="item.payment.payment_pending.status==='COMPLETE'"><br>Completado</small>
                                        <small *ngIf="item.payment.payment_pending.status==='EXPIRED'"><br>No se pudo validar</small>
    
                                        <small *ngIf="item.payment.payment_pending.attempt > 0"><br>{{ item.payment.payment_pending.attempt }} intento(s)</small>
                                        <small *ngIf="item.payment.payment_pending.attempt === 0"><br>Sin iniciar</small>
                                    </div>
                                </div>
    
                            </div>  


                            <div class="row mt-3 row-k" *ngIf="item?.installMent?.length > 0">


                                <div class="fw-bold text-secondary">Distribución</div>
                                <ng-container class="mt-2" *ngFor=" let item2 of item?.installMent ;let i = index">


                                    <div class="col-6">
                                        <div class="small ">
                                            <span>
                                                 {{item2?.installMent?.invoice?.identifier}} / 
                                                {{ item2?.installMent?.order
                                                    < 1 ? 'Inicial': 'Cuota '+ item2?.installMent?.order }} 
                                                    <div class="small text-secondary small">{{item2?.installMent?.code }}</div>

                                            </span>

                                        </div>

                                        <div class="small text-secondary small">{{item?.payment?.installMent?.code }}</div>

                                    </div>
                                    <div class="col-6  text-end ">
                                        <div class=" text-dark"> {{item2?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                    </div>

                                </ng-container>

                            </div>

                            <div class="row row-k" *ngIf="item?.paymen_categ_concept_deduc?.length > 0">

                                <ng-container *ngFor=" let item3 of item?.paymen_categ_concept_deduc ;let i = index">
                                    <div class="col-8" *ngIf="item3?.category_concept_deduction?.concept_deduction?.name == 'IGTF'">
                                        <div class="small">{{item3?.category_concept_deduction?.concept_deduction?.name}}
                                            <span class="text-secondary" *ngIf="item3?.category_concept_deduction.isPorcent">{{item3?.category_concept_deduction?.amount}}%</span> </div>
                                    </div>
                                    <div class="col-4 text-end " *ngIf="item3?.category_concept_deduction?.concept_deduction?.name  == 'IGTF'">
                                        <div class=" text-dark"> {{item3?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                    </div>

                                </ng-container>

                            </div>

                           <div class="row mt-4">


                            <div class="col-12 col-md-8"  *ngIf="!item?.payment?.reconciled_payment &&  item?.payment?.status &&  item?.payment?.typeTransaction!=='PENDING'">
                                        <div class="input-group">

                                            <button (click)="changeSubStatus(item?.payment?.code,subStatus[item.payment.code] == 'REVISION'? 'NA':'REVISION')"  [ngClass]="{'btn-primary': subStatus[item.payment.code] == 'REVISION','btn-secondary':subStatus[item.payment.code]   != 'REVISION' }" class="btn btn-sm ">
                                             {{subStatus[item.payment.code] == 'REVISION'?'Inactivar revisión' : 'Activar revisión'}}
                                            </button>
                                        </div>
                            </div>


                            <div *ngIf="_auth.IsPermitid(['OWNER','ANALIST']) && !item?.payment?.reconciled_payment && item?.payment?.status &&  item?.payment?.typeTransaction!=='PENDING'" class="col-12 col-md-4 text-end">
                                <button [disabled]="loadcon" type="button" (click)="concilidarPago(item?.payment?.code)" class="btn btn-primary mt-2 btn-sm">  
                                    Aprobar
                                    <span *ngIf="loadcon">...</span> </button>
                                <button [disabled]="loadcon2" type="button" (click)="cancelPayment(item?.payment?.code)" class="btn btn-outline-danger  ms-2 mt-2 btn-sm">  
                                    Anular
                                <span *ngIf="loadcon2">...</span> </button>

                            </div>
                        </div>
                        </div>

                        <!-- <pre>{{ resp_paymentsCredits | json }}</pre> -->


                    </div>
                </div>


            </ng-container>


            <div *ngIf="!loading && !loading_person &&  person?.person && resp_paymentsCredits?.length < 1" class="col-12  col-md-8 offset-md-2">
                <div class="mt-5 my-3 p-3 bg-body rounded shadow-sm">
                    <div>No se encontraron pagos registrados</div>
                </div>
            </div>


            <div *ngIf="!loading_person && !person?.person">
                <div>No se encontro person con este id: {{queryParams?.code}}</div>
            </div>


            <ng-container *ngIf="loading_person ||  loading ">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>
        </div>
    </div>

</div>