import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeeService {
  env: any = environment;
  baseUrl;

  constructor(public httpClient: HttpClient) { 
    this.baseUrl = this.env.BASE_LA

  }

  findFeeToApply = (code: any, attribute:any, value: any) => {
    const headers = { 'content-type': 'application/json' };
    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/fees/fee_to_apply', {
          headers: headers,
          params: {
            code, attribute, value
          },
        })
      );
    } catch (error) {
      throw(error);
    }
  }
}
