import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { UserService } from 'src/app/services/user/user.service';
import * as alertifyjs from 'alertifyjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard-fees',
  templateUrl: './dashboard-fees.component.html',
  styleUrls: ['./dashboard-fees.component.css'],
  providers: [DatePipe],

})
export class DashboardFeesComponent implements OnInit {

  plans: any;
  account_payments: any;
  plans_loading: any;
  status_plans: any = true;
  plans_active: any;
  plans_inactive: any;

  code_edit: string = "";
  list_comparison: any = [];
  list_fee_attributes: any = [];

  now: Date = new Date();


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    private offcanvasService: NgbOffcanvas,
    public _auth: AuthService
  ) {}

  ngOnInit(): void {
    this.getFees();
    this.getListComparison();
    this.getListFeeAttributes();
  }

  getListComparison = async () => {
    try {  
      let response = await this._user.getListComparison({});
      this.list_comparison = response.data;
      console.log(this.list_comparison);
    } catch (error: any) {
      this.list_comparison = [];
      console.log(error);
    }
  }

  getListFeeAttributes = async () => {
    try {  
      let response = await this._user.getListFeeAttributes({ status: "ACTIVE"});
      this.list_fee_attributes = response.data;
      console.log(this.list_fee_attributes);
    } catch (error: any) {
      this.list_fee_attributes = [];
      console.log(error);
    }
  }

  getValueByKey = (obj: any, key: string) => {
    const foundItem = obj.find((item:any) => item.key === key);
    return foundItem ? foundItem.value : null;
  }

  getFees = async (plans_loading = true) => {
    try {
      this.plans_loading = plans_loading;

      let plans = await this._user.getFees({});
      this.plans = plans?.data;

      
    } catch (error: any) {
      console.log(error);
    }
    this.plans_loading = false;
  };

  addForm(content: TemplateRef<any>) {
    this.code_edit = "";
    this.pocisionBottom(content);
  }

  edit(content: TemplateRef<any>, code: string) {
    this.code_edit = code;
    this.pocisionBottom(content);
  }

  pocisionBottom = async (content: TemplateRef<any>, data: any = {}) => {
    try {
      this.offcanvasService.open(content, {
        position: 'bottom',
        keyboard: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  delete = async (code: any) => {
    Swal.fire({
      title: "¿Confirma desea eliminar esta comisión?",
      text: "¡No podrás revertir esto!",
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: `Si, continuar!`,
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          if (login?.trim() == '') {
            return Swal.showValidationMessage(`Debe añadir una razón`);
          }
        } catch (error) {
          Swal.showValidationMessage(`
            Request failed: ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          
          await this._user.deleteFee({ code, observation: result?.value });
          alertifyjs.success('eliminación exitosa');
          this.getFees();
        } catch (error: any) {
          alertifyjs.error('Error al eliminación plan');
          console.error(error);
        }
      }
    });


   
  };

  changeStatus = async (code: any, status: any) => {
    Swal.fire({
      title: "¿Confirma desea pausar/reactivar la comisión?",
      text: "¡No podrás revertir esto!",
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: `Si, continuar!`,
      showLoaderOnConfirm: true,
     
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          
          await this._user.changeStatusFee({ code, status: !status });
          this.getFees();
          alertifyjs.success('La comisión fue actualizada');
          
        } catch (error: any) {
          alertifyjs.error('Error actualizando la comisión');
          console.error(error);
        }
      }
    });


   
  };

  

  close() {
    this.offcanvasService.dismiss('');
    this.getFees()
  }


}
